import React from 'react';

function Intro(props) {
  const styles = {
    content: {
      width: "100%",
      borderColor: "green",
      borderStyle: "dotted",
      color: "green",
      fontFamily: "Monospace",
      marginTop: "1px"
    },
    hey: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    textStyle: {
      marginLeft: "40px",
      marginRight: "40px"
    }
  }

  return (
    <div style={styles.content}>
      <div style={{height: "25px"}}/>
      <div style={styles.hey}>
        <img alt="hi-monster" src='/gifs/himonster.gif'></img>
        <h1 style={{...styles.textStyle, textAlign: "center", borderStyle: "dottted", borderColor: "green"}}>
          Hey babe 
        </h1>
      </div>
      <div style={{height: "25px"}}/>
      <h3 style={{...styles.textStyle, textAlign: "left"}}>
        I built a website to figure out Covid shit with you!!!
      </h3>
      <h4 style={{...styles.textStyle, textAlign: "left"}}>
        My hope with this site is to collect my thoughts on this crazy covid stuff so we can become closer and learn how to navigate difficult topics. Also, we're still in a global pandemic that has dramtically affected all walks of life, so being educated on best practices
      </h4>
      <h4 style={{...styles.textStyle, textAlign: "left"}}>
        This website has been an excellent opportunity for me to gather my thoughts rather than being shotgunned by strong opinions left and right.
      </h4>
      <br/>
      <br/>
      <br/>
      <div style={{...styles.hey, flexDirection: "column"}}>
        <img alt="hearts" width="90%" src='/gifs/Heartline1.gif'></img>
        <h3 style={{...styles.textStyle, textAlign: "center"}}>
          Let's get into it and figure this shit out together as a team!
        </h3>
        <img alt="hearts" width="90%" src='/gifs/Heartline1.gif'></img>
      </div>      
      <br/>
      <br/>
      <p style={{...styles.textStyle, textAlign: "left"}}>
        p.s I'm making this website in green cuz I have an aversion to the color green so I figured using it for my covid site would be appropro. Trying to deal with scary stuff.
      </p>
      <div style={{height: "25px"}}/>
      {/* <div style={styles.hey}>
        <h2>
          FUCK COVID!!
        </h2>
        <img alt="sexskel" src='/gifs/horror003.gif'></img>
        <h2>
          AMIRIGHT??
        </h2>
      </div> */}
    </div>
  );
}

export default Intro;