import React, {useState} from 'react';

function Password({enterSite, correctAnswer}) {
  
  const styles = {
    header: {
      width: "100%",
      height: "400px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderColor: "green",
      borderStyle: "dotted",
      marginTop: "60px",
      flexDirection: "column"

    },
    scaryText: {
      fontFamily: "Covid19",
      fontSize: "4em",
      color: "green",
      marginLeft: "10px",
      marginRight: "10px",
    }
  }
  
  
  const [answer, setAnswer] = useState("")


  const handleChange = (e) => {
    setAnswer(e.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (answer.toLowerCase() === correctAnswer) {
      enterSite()
    } else {
      setAnswer("incorrect passcode")
    }
  }

  return (
    <div style={styles.header}>
      <h3 style={styles.scaryText}> Enter the passcode</h3>
      <form onSubmit={handleSubmit}>
        <label>
        <input type={"text"} value={answer} onChange={handleChange}/>
        </label>
        <input type={"submit"} value={"Submit"}/>
      </form>
      <div style={{height: 10}}/>
    </div>

  );
}

export default Password;