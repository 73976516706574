import React from 'react';

function TheDisease(props) {

  const styles = {
    content: {
      width: "100%",
      borderColor: "green",
      borderStyle: "dotted",
      color: "green",
      fontFamily: "Monospace",
      marginTop: "1px"
    },
    hey: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    textStyle: {
      marginLeft: "40px",
      marginRight: "40px"
    }
  }

  return (
    <div style={styles.content}>
      <div style={styles.textStyle}>

        <h1><u>Intro</u></h1>
        <p>
          This shit has been really hard.
        </p>
        <br/>
        <p>
          On one hand, I've have you, my girlfriend with an immunocompromised Mom who is understandably worried about contracting a disease that's killed over a million people in the US alone. Because of this disease, you haven't been able to leave the house for long streches of time, see family or fiends on a regular basis, and have had to walk around in the house with a mask under the threat that any wrong move (pretty much all of your favorite things) could potentially kill your Mom as well as other family members.
        </p>
        <br/>
        <p>
          At the same time, I've had family members who have gotten very sick from covid, nearly had their business destroyed, and have been repeatedly demonized because of decisions they've made regarding vaccination. Covid create extreme strife among family members that's taken the forms of ostracisim, insults, and harsh judgements. At this point the thought of everyone coming together brings existential dread.
        </p>
        <br/>
        <p>
          To make matters even more complicated, my family and you have totally opposing veiws regarding the appropriate approach to this pandemic and I've been caught in the center. This has sucked. I hate it, and I want to bridge the gap. I'm hoping to do that by  doing the work and figuring out what's going on with this whole pandemic thing. I'm going to write out all the stuff I think and what I've found via research. Then we can go though all of it, smash it with a hammer and figure out what's true.
        </p>
        <br/>
        <br/>
        <p>
          Ok, let's get into it.
        </p>
        <h1><u>The Disease</u></h1>
        <p>
          I'm going to start with what I understand about the disease.
        </p>
        <br/>
        <p>
          Covid19 is an airborn disease transmitted via aerosals. The amount of viral load determines the severity of infection. Thus prolonged periods of time in an unventallated space is the key equation that determines the severity of illness. Risk is also determined by age with older people more suseptible to severe infection than younger poeple. This lines up with the initial reports of superspreader events. They took place in confined spaces without circulating  air. This also makes the  along with my own personal experience of contracting the disease, as it appeared to become much worse after prolonged contact with other family members. The disease initially builds up in the nose and throat then spreads throughout the body. Covid have been found in the blood, in the brain, all the organs, all over. People typically die from covid because it will replicate very rapidly in the lungs. The immune system overreacts and starts fucking shit up, this causes pneumonia and that's when people die. *** I'm not sure what's goig on with all the long hauler symptoms, but I do know that fatigue, loss of smell/taste, and inhibited brain function can all persist well after infection. Covid also makes you more suseptible to lots of other bad things like heart attacks, stroke, myocardytis *** This is at least how the OG covid I got worked. From what I understand, Omi is a much more mild version of the bad covid, but long hauler symptoms are still a concern.
        </p>
        <br/>
        <h1><u>Covid by the Numbers</u></h1>
        <p>
          According to the world health organization there have been 486,761,597 confirmed cases world wide and 6,142,735 deaths given covid a death rate of 0.0126. Deviding the weekly number of deaths by cases from usa facts omi has a deathrate of roughly 0.0031. Accorinding to the graph, death the death rate of COVID has skyrocketed, but I believe this is due to widespread self tests and asymptomatic cases. I'm sure the rate is much higher.
        </p>
        <br/>
        <h4>
          This is as far as I got in making the website. I'll add links below as I find them below
        </h4>
        <br/>
        <a href="https://rumble.com/v21ahhm-new-zealand-documentary-memorial-day-silent-no-more-nz.html" target="_blank" rel="noreferrer">
          New Zealand Documentary on the Vaccine Injured
        </a>
        <br/>
        <a href="https://www.tabletmag.com/sections/[sectionSlug]/articles/needle-points-vaccinations-chapter-one" target="_blank" rel="noreferrer">
          Article taking a deep dive on the Vaccine Hesitant
        </a>
        <br/>
        <a href='https://www.youtube.com/watch?v=gRiDgasJDNs&ab_channel=9NewsAustralia'>
          Doctor in Australia talking about vaccine injury on mainstream news
        </a>
        <br/>
        <a href='https://www.youtube.com/watch?v=pfgGCgxGYkk&ab_channel=UKParliament' target="_blank" rel="noreferrer"> UK Parliament date regarding saftey and efficacy of vaccines</a>
        <br/>
        <a href='https://www.youtube.com/watch?v=Yl0qkt5YXrs&ab_channel=GBNews' target="_blank" rel="noreferrer"> Discussion on British news regarding rising death rates among young sports players</a>
        <br/>
        <a href="https://drturner.substack.com/p/the-truth-about-covid-vaccines-and" target="_blank" rel="noreferrer"> Article on the Spike Protein that I received Dr.Turner</a>
        <br/>
        <a href="https://drturner.substack.com/p/save-yourself-from-the-covid-spike" target="_blank" rel="noreferrer"> Another Article on the Spike Protein that I received Dr.Turner</a>
        <br/>
        <a href="https://drturner.substack.com/p/losing-my-vaccine-religion-a-doctors" target="_blank" rel="noreferrer"> Final Article from Dr.Turner on why he believes what he believes</a>
        <br/>

        <h4>
          Love you. I'm serious about figuring this stuff out with you. And I'm sorry everything has sucked so hard. I also want apologies for my lack of follow through. I want to do the stuff I'll say I'll do. {"<3"}
        </h4>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        </div>
    </div>
  );
}

export default TheDisease;