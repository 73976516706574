import React from 'react';

function Header(props) {


  const styles = {
    header: {
      width: "100%",
      height: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderColor: "green",
      borderStyle: "dotted",
      marginTop: "20px"

    },
    scaryText: {
      fontFamily: "Covid19",
      fontSize: "4em",
      color: "green",
      marginLeft: "10px",
      marginRight: "10px",
    }
  }

  return (
    <div style={styles.header}>
      <img alt={"virus"} src="/gifs/virus.gif"></img>
      <h1 style={styles.scaryText}> COVID SUX </h1>
      <img alt={"virus"} src="/gifs/virus.gif"></img>
    </div>
  );
}

export default Header;