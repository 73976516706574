import { useState } from 'react';
import Header from './components/Header';
import Intro from './components/Intro';
import Password from './components/Password';
import TheDisease from './components/TheDisease';
import useWindowSize from './hooks/useWindowSize';

function App() {
  const [passcodeCorrect, setPasscodeCodeCorrect] = useState(false)
  const [width] = useWindowSize()
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    marginLeft: width < 700 ? "2.5%" : "10%",
    width: width < 700 ? "95%" : "80%",
    justifyContent: "center",
    alignItems: "center"
  }

  const enterSite = () => setPasscodeCodeCorrect(true)

  return (
    <div style={containerStyle}>
      {
      passcodeCorrect ?
        <>
          <Header/>
          <Intro/>
          <TheDisease/>
        </>
      : <Password enterSite={enterSite} correctAnswer={"batsax"} />
      }

    </div>
  )
}
export default App;
